import React, { Fragment } from 'react';
import { modalStore } from '../layout/modals/ModalStore';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormField } from '../layout/form-fields/FormField';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { candidatesStore } from './CandidatesStore';
import { CountryDropdown } from '../layout/form-fields/inputs/CountryDropdown';
import { MultiDropdown } from 'components/layout/form-fields/inputs/MultiDropdown';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	email: Yup.string().required().email().label('Email')
});

export class CandidateEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					name: this.props.candidate.name,
					email: this.props.candidate.email,
					location: this.props.candidate.location || '',
					disabled: this.props.candidate.disabled,
					countryId: this.props.candidate.countryId || '',
					aboutMe: this.props.candidate.aboutMe || '',
					topSkills: this.props.candidate.topSkills || []
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'CandidateEdit')}
			</Formik>
		);
	}

	renderEditName() {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	renderEditLocation(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">Country</label>
							<div className="control">
								<CountryDropdown
									value={this.props.candidate.country}
									onValueChange={item => {
										formikBag.setFieldValue('countryId', item.id);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="City/State"
							field="location"
							type="text"
							placeholder="City/State"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	renderEditAbout() {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							field="aboutMe"
							maxlength={200}
							type="textarea"
							placeholder="Please write a brief summary of your professional profile. Remember to do write about yourself in the 3rd person!."
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	getTopSkillsFromProjects() {
		const skillsMap = {};

		this.props.candidate.candidateExperiences.forEach(experience => {
			experience.skills.forEach(skill => {
				skill.text = skill.name;
				skill.value = skill.id;
				skillsMap[skill.name] = skill;
			});
		});

		return Object.values(skillsMap);
	}

	parseSkillsValue(skillsValue) {
		const parsedSkillsValue = skillsValue;

		parsedSkillsValue.forEach(skill => {
			skill.text = skill.name;
			skill.value = skill.id;
		});

		return parsedSkillsValue;
	}

	renderEditTopSkills(formikBag) {
		const skillOptions = this.getTopSkillsFromProjects();

		const skillsValue = this.parseSkillsValue(formikBag.values.topSkills);

		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">Top Technologies</label>
							<MultiDropdown
								placeholder="Top Technologies"
								options={skillOptions}
								value={skillsValue}
								onValueChange={value => {
									formikBag.setFieldValue('topSkills', value);
								}}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				{
					{
						name: this.renderEditName(formikBag),
						location: this.renderEditLocation(formikBag),
						about: this.renderEditAbout(formikBag),
						topSkills: this.renderEditTopSkills(formikBag)
					}[this.props.editAttribute]
				}
			</Fragment>
		);
	}

	async save(values) {
		Object.keys(values).forEach(
			key => values[key] === this.props.candidate[key] && delete values[key]
		);
		candidatesStore
			.update(this.props.candidate.id, {
				candidate: values
			})
			.then(result => {
				if (result.status === 'success') {
					notificationStore.pushNotification({
						type: notificationStore.SUCCESS_NOTIFICATION,
						title: 'Edit Candidate',
						message: 'The candidate has been successfully created.'
					});
					this.closeWithSuccess();
				} else {
					notificationStore.pushNotification({
						type: notificationStore.ERROR_NOTIFICATION,
						title: 'Edit Candidate',
						message: 'An error has occurred. Please try again.'
					});
					modalStore.closeDialog();
				}
			});
	}
}
