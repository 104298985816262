import React, { useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';

const STEP = 1;

export const RangeInput = ({
	value,
	min = 0,
	max = 100,
	onChange,
	onFinalChange
}) => {
	const [values, setValues] = useState([min, max]);

	useEffect(() => {
		if (value) {
			setValues(value);
		}
	}, [value]);

	return (
		<div
			className="range-input"
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexWrap: 'wrap'
			}}>
			<Range
				values={values}
				step={STEP}
				min={min}
				max={max}
				onChange={values => {
					setValues(values);
					if (onChange) {
						onChange(values);
					}
				}}
				onFinalChange={values => {
					if (onFinalChange) {
						onFinalChange(values);
					}
				}}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={{
							...props.style,
							height: '36px',
							display: 'flex',
							width: '100%'
						}}>
						<div
							ref={props.ref}
							style={{
								height: '5px',
								width: '100%',
								borderRadius: '4px',
								background: getTrackBackground({
									values,
									colors: ['#ccc', '#548BF4', '#ccc'],
									min: min,
									max: max
								}),
								alignSelf: 'center'
							}}>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props, value }) => (
					<div
						{...props}
						key={props.key}
						style={{
							...props.style,
							height: '20px',
							width: '20px',
							borderRadius: '50%',
							backgroundColor: '#FFF',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							boxShadow: '0px 2px 6px #AAA',
							outline: 'none'
						}}>
						<div
							style={{
								marginTop: '40px'
							}}>
							{value}%
						</div>
					</div>
				)}
			/>
		</div>
	);
};
