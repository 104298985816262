import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { notificationStore } from '../../../components/layout/notifications/NotificationStore';
import { Button } from '../../../components/layout/button/Button';
import { FormField } from '../../../components/layout/form-fields/FormField';
import { PhoneInput } from '../../../components/layout/form-fields/inputs/PhoneInput';
import { candidatesStore } from '../CandidatesStore';
import { CountryDropdown } from '../../../components/layout/form-fields/inputs/CountryDropdown';
import { MultiDropdown } from '../../../components/layout/form-fields/inputs/MultiDropdown';
import { ReferrerInput } from '../../../components/layout/form-fields/inputs/ReferrerInput';
import { FormFieldError } from '../../../components/layout/form-fields/FormFieldError';
import DropdownField from '../../../components/layout/form-fields/DropdownField';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	phoneNumber: Yup.string()
		.nullable()
		.required()
		.label('Phone')
		.test(
			'phone-number-required',
			'Phone number is a required field',
			function (value) {
				const phoneNumber = value?.split(' ')?.[1];
				return value && phoneNumber && phoneNumber !== 'undefined';
			}
		),
	phoneNumberCountry: Yup.string().nullable().required().label('Phone Country'),
	location: Yup.string().required().label('Location'),
	linkedinUrl: Yup.string()
		.required('Linkedin profile is a required field')
		.label('LinkedIn profile')
		.url()
		.test('valid-link', 'LinkedIn profile is a required field', value => {
			return value !== 'https://www.linkedin.com/in/';
		}),
	country: Yup.object().nullable().required().label('Country'),
	preferredContactMethod: Yup.array()
		.min(1, 'Please select at least one Contact Method')
		.required()
		.label('Contact Method'),
	email: Yup.string().required().email().label('Email')
});

const ValidationTalentNetworkSourceSchema = Yup.object().shape({
	talentNetworkSource: Yup.string()
		.nullable()
		.required()
		.label('Talent Network Source')
});

export class CandidateProfileContactInfoEdit extends React.Component {
	state = {
		saving: false
	};
	getValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	isInitialValid(props) {
		if (!props.validationSchema) return true;
		return props.validationSchema.isValidSync(props.initialValues);
	}
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				isInitialValid={this.isInitialValid}
				validationSchema={
					this.props.isWizard
						? ValidationSchema.concat(ValidationTalentNetworkSourceSchema)
						: ValidationSchema
				}
				validateOnChange
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderForm(formikBag)}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="expectations-edit maxWidth">
				<Fragment>
					<section className="hero">
						<div className="hero-body">
							<div className="container has-text-centered">
								<h1 className="title is-2 title-edit">Contact Info</h1>
							</div>
						</div>
					</section>
					<section className="section">
						<div className="container">
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<div className="field has-text-left">
										<FormField
											className="has-text-left"
											field="name"
											styleVersion="v2"
											type="text"
											placeholder="Name *"
											isInvalid={
												formikBag.touched.name && formikBag.errors.name
											}
										/>
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-6">
									<div className="field has-text-left">
										<FormField
											className="has-text-left"
											field="email"
											styleVersion="v2"
											type="email"
											placeholder="Email *"
											isInvalid={
												formikBag.touched.email && formikBag.errors.email
											}
										/>
									</div>
								</div>
								<div className="column is-6">
									<div className="has-text-left">
										<PhoneInput
											phoneNumberValue={formikBag.values.phoneNumber}
											phoneCountryValue={formikBag.values.phoneNumberCountry}
											handlePhoneInputChange={value => {
												formikBag.setFieldValue('phoneNumber', value);
												formikBag.setFieldTouched('phoneNumber', true, false);
											}}
											handlePhoneCountryChange={value => {
												formikBag.setFieldValue('phoneNumberCountry', value);
												formikBag.setFieldTouched(
													'phoneNumberCountry',
													true,
													false
												);
											}}
											phoneNumberPlaceholder="Phone number *"
											phoneNumberCountryPlaceholder="Country *"
											isInvalidPhoneNumber={
												formikBag.touched.phoneNumber &&
												formikBag.errors.phoneNumber
											}
											isInvalidPhoneNumberCountry={
												formikBag.touched.phoneNumberCountry &&
												formikBag.errors.phoneNumberCountry
											}
										/>
										<FormFieldError field="phoneNumberCountry" />
										<FormFieldError field="phoneNumber" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-6">
									<div className="field has-text-left">
										<FormField
											className="has-text-left"
											styleVersion="v2"
											field="location"
											type="text"
											placeholder="City/State *"
											isInvalid={
												formikBag.touched.location && formikBag.errors.location
											}
										/>
									</div>
								</div>
								<div className="column is-6">
									<div className="field has-text-left">
										<DropdownField
											component={CountryDropdown}
											name="country"
											styleVersion="v2"
											placeholder="Country *"
											onValueChange={item => {
												formikBag.setFieldValue('country', item);
												formikBag.setFieldTouched('country', true, false);
											}}
										/>
										<FormFieldError field="country" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<div className="field has-text-left">
										<FormField
											className="has-text-left"
											styleVersion="v2"
											field="linkedinUrl"
											type="text"
											placeholder="Linkedin profile *"
											onChange={event => {
												const newValue = event.target.value.split('/');
												const linkedId =
													newValue.length > 4 ? newValue.pop() : '';
												formikBag.setFieldValue(
													'linkedinUrl',
													`https://www.linkedin.com/in/${linkedId}`
												);
											}}
											isInvalid={
												formikBag.touched.linkedinUrl &&
												formikBag.errors.linkedinUrl
											}
										/>
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-full">
									<div className="field has-text-left">
										<DropdownField
											component={MultiDropdown}
											name="preferredContactMethod"
											styleVersion="v2"
											placeholder="What`s your preferred contact method *"
											options={candidatesStore.preferredContactMethodOptions}
										/>
										<FormFieldError field="preferredContactMethod" />
									</div>
								</div>
							</div>
							{this.props.isWizard ? (
								<div className="columns wizard-row">
									<div className="column is-fullwidth">
										<ReferrerInput
											options={candidatesStore.talentNetworkSourceOptions}
											fieldInput={'talentNetworkSourceOther'}
											inputValue={formikBag.values.talentNetworkSourceOther}
											radioValue={formikBag.values.talentNetworkSource}
											inputPlaceholder={'Please specify'}
											optionsToShowInput={['other', 'referred_acquaintance']}
											optionRecruiterDropdown={['referred_prediktive']}
											label={
												"How did you find out about Prediktive's Talent Network?"
											}
											handleOnChangeRadio={value => {
												formikBag.setFieldValue('talentNetworkSource', value);
												formikBag.setFieldTouched(
													'talentNetworkSource',
													true,
													false
												);
												document.body.scrollTo(0, 10000);
											}}
											handleOnChangeInput={value => {
												formikBag.setFieldValue(
													'talentNetworkSourceOther',
													value
												);
												formikBag.setFieldTouched(
													'talentNetworkSourceOther',
													true,
													false
												);
											}}
											handleOnChangeRecruiterDropdown={value => {
												formikBag.setFieldValue(
													'talentNetworkSourceRecruiter',
													value
												);
												formikBag.setFieldTouched(
													'talentNetworkSourceRecruiter',
													true,
													false
												);
											}}
											required
										/>
										<FormFieldError field="talentNetworkSource" />
										<FormFieldError field="talentNetworkSourceOther" />
										<FormFieldError field="talentNetworkSourceRecruiter" />
									</div>
								</div>
							) : null}
						</div>
					</section>
					<footer className="footer">
						<div className="container">
							<div className="content has-text-centered">
								{this.props.isWizard ? (
									<div>
										<Button
											type="button"
											className="button is-light is-large"
											onClick={() => this.props.handleBack()}
											label={'Back'}
										/>

										<Button
											onClick={() => {
												formikBag.submitForm();
											}}
											type="submit"
											className="button primary-v2 is-large"
											label={'Continue'}
											loading={this.state.saving}
										/>
									</div>
								) : (
									<Button
										onClick={() => {
											formikBag.submitForm();
										}}
										type="submit"
										className="button primary-v2 is-large"
										label={'Save'}
										loading={this.state.saving}
									/>
								)}
							</div>
						</div>
					</footer>
				</Fragment>
			</div>
		);
	}

	getInitialValues() {
		const preferredContactMethod = (
			this.props.candidate.preferredContactMethod || []
		).map(item => {
			return {
				text: this.getValueFromOptions(
					item,
					candidatesStore.preferredContactMethodOptions
				),
				value: item
			};
		});
		return {
			name: this.props.candidate.name,
			email: this.props.candidate.preferredEmail
				? this.props.candidate.preferredEmail
				: this.props.candidate.email,
			phoneNumber: this.props.candidate.phoneNumber,
			phoneNumberCountry: this.props.candidate.phoneNumberCountry,
			country: this.props.candidate.country,
			talentNetworkSource: this.props.candidate.talentNetworkSource,
			talentNetworkSourceOther: this.props.candidate.talentNetworkSourceOther,
			linkedinUrl:
				this.props.candidate.linkedinUrl || 'https://www.linkedin.com/in/',
			location: this.props.candidate.location || '',
			preferredContactMethod
		};
	}

	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}

	async save(values) {
		const valuesToSave = {
			candidate: {
				...values,
				country: values.country.id ? values.country.id : values.country,
				preferredContactMethod: values.preferredContactMethod.map(
					item => item.value
				)
			},
			isWizard: this.props.isWizard
		};
		candidatesStore.updateContactInfo(valuesToSave).then(result => {
			this.setState({ saving: false });
			if (result.status === 'success') {
				notificationStore.pushNotification({
					type: notificationStore.SUCCESS_NOTIFICATION,
					title: 'Contact info saved',
					message: 'Your contact info was saved successfully.'
				});
				this.props.handleSuccess();
			} else {
				notificationStore.pushNotification({
					type: notificationStore.ERROR_NOTIFICATION,
					title: 'Contact Info error',
					message: 'An error has occurred. Please try again.'
				});
			}
		});
	}
}
