import React from 'react';
import { withRouter } from 'react-router-dom';
import { candidatesStore } from './CandidatesStore';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { CandidatesTable } from './CandidatesTable';
import { CandidateCreate } from './CandidateCreate';
import { CandidateEdit } from './CandidateEdit';
import { CandidateProfileLinkToken } from './profile/link-token/CandidateProfileLinkToken';
import { ListStore } from '../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { ListHeaderComponent } from '../layout/list/ListHeaderComponent';
import { modalStore } from '../layout/modals/ModalStore';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';
import { dialogStore } from '../layout/modals/DialogStore';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { SkillDropdown } from './SkillDropdown';
import { TagsDropdown } from './TagsDropdown';
import { Dropdown } from './Dropdown';
import { CREATED_ON_FILTER_OPTIONS } from './constants';
import { CandidatesTablePagination } from './shared/CandidatesTablePagination';
import { CandidatesTableLimitSelect } from './shared/CandidatesTableLimitSelect';
import CandidatesTableFilterList from './shared/CandidatesTableFilterList';
import { skillStore } from '../../components/settings/skills/SkillStore';
import { tagStore } from '../../components/settings/tags/TagStore';
import { CountryDropdown } from './CountryDropdown';
import _ from 'lodash';
import { countryStore } from 'components/settings/countries/CountryStore';
import Select from 'react-select';
import { ScorecardTemplateDropdown } from 'components/scorecard-templates/components/ScorecardTemplateDropdown';
import { RangeInput } from 'components/layout/form-fields/inputs/RangeInput';

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.RECRUITER])
@withRouter
@observer
export class Candidates extends React.Component {
	state = {
		filterSkills: null,
		filterText: null,
		filterTags: null,
		filterCountries: null,
		filterCreatedOn: null,
		filterPage: null,
		filterScore: null,
		filterStatus: null,
		filterLookingForJob: null,
		showFilters: false,
		showColumnsFilter: false,
		visibleColumns: [],
		scorecardTemplates: []
	};
	constructor(props) {
		super(props);
		this.listStore = new ListStore(candidatesStore, {
			entityName: 'Candidate',
			createModal: (
				<CandidateCreate
					handleSuccess={result => {
						if (result && result.data.id) {
							this.props.history.push(
								`/candidates/profile/${result.data.id}/edit`
							);
						}
					}}
				/>
			),
			createModalTitle: 'Create Candidate',
			editModal: item => (
				<CandidateEdit
					candidate={item}
					handleSuccess={() => this.listStore.search()}
				/>
			)
		});
	}

	componentDidMount() {
		this.setQueryStrings(this.props.location.search);

		const showFilters =
			localStorage.getItem('pbookShowFilters') === 'false' ? false : true;

		this.setState({ showFilters });

		const showColumnsFilter =
			localStorage.getItem('pbookShowColumnsFilter') === 'false' ? false : true;

		this.setState({ showColumnsFilter });

		const visibleColumns =
			JSON.parse(localStorage.getItem('pbookVisibleColumns')) ||
			candidatesStore.tableColumnsOptions;

		this.setState({ visibleColumns });
	}

	setQueryStrings = async source => {
		const FIRST_PAGE_INDEX = 1;
		const DEFAULT_ORDER_ATTRIBUTE = 'name';
		const DEFAULT_ORDER = 'true';
		const DEFAULT_STATUS = 'active';
		const queryParameters = new URLSearchParams(
			source || this.props.location.search
		);
		const queryText = queryParameters.get('text');
		const queryTags = queryParameters.get('tags');
		const queryStatus = queryParameters.get('status') || DEFAULT_STATUS;
		const queryLookingForJob = queryParameters.get('lookingForJob') || '';
		const querySkills = queryParameters.get('skills');
		const queryCountries = queryParameters.get('countries');
		const queryScore = queryParameters.get('scoreRange') || '0,100';
		const queryCreatedOn =
			queryParameters.get('createdOn') || CREATED_ON_FILTER_OPTIONS[0].value;
		const queryColumn =
			queryParameters.get('column') || DEFAULT_ORDER_ATTRIBUTE;
		const queryAsc = queryParameters.get('asc') || DEFAULT_ORDER;
		const queryPage = queryParameters.get('page') || FIRST_PAGE_INDEX;
		candidatesStore.setQuery(
			'skills',
			querySkills ? querySkills.split(',') : null
		);
		candidatesStore.setQuery('tags', queryTags ? queryTags.split(',') : null);
		candidatesStore.setQuery('scoreRange', queryScore);
		candidatesStore.setQuery('createdOn', queryCreatedOn);
		candidatesStore.setQuery('text', queryText);
		candidatesStore.setQuery('status', queryStatus);
		candidatesStore.setQuery(
			'countries',
			queryCountries ? queryCountries.split(',') : null
		);
		const scorecardTemplates =
			JSON.parse(localStorage.getItem('pbookScorecardTemplates')) || [];

		let templatesIds = (scorecardTemplates || []).map(template => template.id);

		candidatesStore.setQuery('scorecardTemplates', templatesIds);

		this.setState({ scorecardTemplates });
		candidatesStore.setQuery('lookingForJob', queryLookingForJob);
		candidatesStore.setQuery('sort', {
			column: queryColumn,
			asc: queryAsc === 'true'
		});
		candidatesStore.setQuery('pagination', {
			page: queryPage,
			limit:
				candidatesStore.query.limit || candidatesStore.defaultPagination.limit,
			totalResults: candidatesStore.query.totalResults
		});
		this.setState({ filterStatus: queryStatus });

		this.setState({ filterLookingForJob: queryLookingForJob });

		const filteredSkills = querySkills
			? await Promise.all(
					querySkills
						.split(',')
						.map(async skillName =>
							(await this.getSkills(skillName)).find(
								skill => skill.name === skillName
							)
						)
				)
			: [];

		this.setState({
			filterSkills: filteredSkills
		});

		const filteredTags = queryTags
			? await Promise.all(
					queryTags
						.split(',')
						.map(async tagName =>
							(await this.getTags(tagName)).find(tag => tag.name === tagName)
						)
				)
			: [];
		this.setState({ filterTags: filteredTags });

		const filteredCountries = queryCountries
			? await Promise.all(
					queryCountries
						.split(',')
						.map(async countryName =>
							(await this.getCountries(countryName)).find(
								country => country.name === countryName
							)
						)
				)
			: [];

		this.setState({
			filterCountries: filteredCountries
		});

		this.setState({ filterText: queryText });
		this.setState({ filterPage: queryPage });
		this.setState({ filterScore: queryScore });
		this.setState({ filterCreatedOn: queryCreatedOn });
		this.listStore.handlePaginationChange(
			parseInt(queryPage),
			candidatesStore.query.pagination.limit
		);
	};

	preSetQuery() {
		candidatesStore.setQuery('sort', { column: 'name', asc: true });
		return true;
	}

	handleEdit = item => {
		modalStore.openModal(
			{ title: 'Edit Profile', position: 'center' },
			this.listStore.modalsConfigs.editModal(item)
		);
	};

	renderFilterCount = filterResultData => {
		return (
			<div className="filterResultsCount">
				Showing {filterResultData.start}-{filterResultData.end} of{' '}
				{filterResultData.totalResults} total candidates
			</div>
		);
	};

	handleSearchByText = async text => {
		this.setState({ filterText: text });

		this.handleSearchByTextDebounced(text);
	};

	handleSearchByTextDebounced = _.debounce(text => {
		candidatesStore.setQuery('text', text);
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	}, 400);

	handleSearchBySkills = async skills => {
		let skillsNames = (skills || []).map(skill => skill.name);
		candidatesStore.setQuery('skills', skillsNames);
		this.setState({ filterSkills: skills || [] });
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	};

	handleSearchByTags = tags => {
		let tagsNames = (tags || []).map(tag => tag.name);
		candidatesStore.setQuery('tags', tagsNames);
		this.setState({ filterTags: tags || [] });
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	};

	handleSearchByCountry = countries => {
		let items = (countries || []).map(item => item.name);
		candidatesStore.setQuery('countries', items);
		this.setState({ filterCountries: countries || [] });
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	};

	handleSearchByStatus = async status => {
		candidatesStore.setQuery('status', status);
		this.setState({ filterStatus: status });
		this.updateQueryParams();
		this.listStore.search();
	};

	handleSearchByCreatedOn = async createdOn => {
		candidatesStore.setQuery('createdOn', createdOn);
		this.setState({ filterCreatedOn: createdOn });
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	};

	handleSearchByLookingForJob = async value => {
		candidatesStore.setQuery('lookingForJob', value);
		this.setState({ filterLookingForJob: value });
		this.updateQueryParams();
		this.listStore.search();
	};

	handleSearchByScore = async scoreRange => {
		const parsedScoreRange = scoreRange.join(',');
		candidatesStore.setQuery('scoreRange', parsedScoreRange);
		this.setState({ filterScore: parsedScoreRange });
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	};

	resetPagination = _ => {
		candidatesStore.setQuery('pagination', {
			page: candidatesStore.defaultPagination.page,
			limit: candidatesStore.query.pagination.limit
		});
	};

	updateQueryParams = _ => {
		const queryParams = Object.assign(
			{},
			candidatesStore.query.text && { text: candidatesStore.query.text },
			(candidatesStore.query.tags || []).length > 0 && {
				tags: candidatesStore.query.tags
			},
			(candidatesStore.query.skills || []).length > 0 && {
				skills: candidatesStore.query.skills
			},
			(candidatesStore.query.countries || []).length > 0 && {
				countries: candidatesStore.query.countries
			},
			candidatesStore.query.scoreRange && {
				scoreRange: candidatesStore.query.scoreRange
			},
			candidatesStore.query.createdOn && {
				createdOn: candidatesStore.query.createdOn
			},
			candidatesStore.query.pagination.page && {
				page: candidatesStore.query.pagination.page
			},
			candidatesStore.query.status && {
				status: candidatesStore.query.status
			},
			candidatesStore.query.lookingForJob && {
				lookingForJob: candidatesStore.query.lookingForJob
			},
			candidatesStore.query.sort
		);
		window.history.replaceState(
			null,
			null,
			'?' + new URLSearchParams(queryParams).toString()
		);
	};

	handleShowFilters = (close = false) => {
		const value = close ? false : !this.state.showFilters;

		this.setState({ showFilters: value });

		localStorage.setItem('pbookShowFilters', value);
	};

	handleShowColumnsFilter = (close = false) => {
		const value = close ? false : !this.state.showColumnsFilter;

		this.setState({ showColumnsFilter: value });

		localStorage.setItem('pbookShowColumnsFilter', value);
	};

	handleVisibleColumns = value => {
		this.setState({ visibleColumns: value });

		localStorage.setItem('pbookVisibleColumns', JSON.stringify(value));
	};

	handleScorecardTemplates = templates => {
		this.setState({ scorecardTemplates: templates });

		localStorage.setItem('pbookScorecardTemplates', JSON.stringify(templates));

		let templatesIds = (templates || []).map(template => template.id);
		candidatesStore.setQuery('scorecardTemplates', templatesIds);
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.search();
	};

	handleClearFilters = async () => {
		await this.setQueryStrings({});
		this.updateQueryParams();
	};

	render() {
		// Empty string is considered a value for these fields.
		const showScoreFilter =
			this.state.filterScore || this.state.filterScore === '';

		const showCreatedOnFilter =
			this.state.filterCreatedOn || this.state.filterCreatedOn === '';

		const showStatusFilter =
			this.state.filterStatus || this.state.filterStatus === '';

		const showLookingForJobFilter =
			this.state.filterLookingForJob || this.state.filterLookingForJob === '';

		return (
			<div className="Candidates container is-max-widescreen">
				<ListHeaderComponent
					title={`${this.listStore.modalsConfigs.entityName}s`}
					disableCreation={true}
					createActionTitle={this.listStore.modalsConfigs.createModalTitle}
					handleCreate={() => this.listStore.handleCreate()}
					actions={
						<button
							className="button is-primary is-rounded main-action-button"
							onClick={() => candidatesStore.exportCandidates()}>
							Export to CSV
						</button>
					}
				/>
				<div className="columns no-padding is-multiline"></div>
				<div className="columns">
					<div
						className={`column ${
							this.state.showFilters && this.state.showColumnsFilter
								? 'is-8'
								: this.state.showFilters || this.state.showColumnsFilter
									? 'is-10'
									: 'is-12'
						} list-container rows`}>
						<div className="columns no-padding">
							<div className="column">
								<CandidatesTableLimitSelect
									loading={this.listStore.loading}
									pagination={candidatesStore.query.pagination}
									onPaginationChange={this.handlePaginationChange}
								/>
							</div>
							<div className="column is-3">
								<InputField
									key={`${Math.floor(Math.random() * 1000)}`}
									resetInput={true}
									autoFocus={true}
									className="has-text-left no-margin-bottom"
									field="title"
									type="search"
									icon="fa-search"
									defaultValue={this.state.filterText}
									placeholder="Search"
									onValueChange={this.handleSearchByText}
								/>
							</div>
							{this.state.showFilters ? null : (
								<div className="column show-filter-button is-2">
									<button onClick={() => this.handleShowFilters()}>
										Show Filters
										<span class="icon is-small">
											<i class="fas fa-filter" aria-hidden="true"></i>
										</span>
									</button>
								</div>
							)}
							{this.state.showColumnsFilter ? null : (
								<div className="column show-filter-button is-2">
									<button onClick={() => this.handleShowColumnsFilter()}>
										View Columns
										<span class="icon is-small">
											<i class="fas fa-bars" aria-hidden="true"></i>
										</span>
									</button>
								</div>
							)}
						</div>
						{this.renderFilterCount(candidatesStore.query.pagination)}
						<div className="row is-12 table-wrapper">
							<CandidatesTable
								data={this.listStore.data}
								sort={this.listStore.store.query.sort}
								onSort={this.handleSort}
								onEdit={this.handleEdit}
								onDisable={this.listStore.handleDisable}
								onDelete={this.handleDelete}
								onActivate={this.handleActivation}
								onGenerateLinkToken={this.handleGenerateLinkToken}
								visibleColumns={this.state.visibleColumns}
								scorecardTemplates={this.state.scorecardTemplates}
							/>
						</div>
						<div className="row is-12 bottom-pagination">
							<CandidatesTablePagination
								loading={this.listStore.loading}
								pagination={candidatesStore.query.pagination}
								onPaginationChange={this.handlePaginationChange}
							/>
						</div>
					</div>
					{this.state.showFilters ? (
						<div className="column is-2">
							<CandidatesTableFilterList
								title="Filter List"
								onClose={() => this.handleShowFilters(true)}
								clearText="Clear Filters"
								onClear={this.handleClearFilters}>
								<div className="filter-container column no-padding">
									{this.state.filterSkills ? (
										<>
											<label>Technologies</label>
											<SkillDropdown
												onValueChange={this.handleSearchBySkills}
												value={this.state.filterSkills}
											/>
										</>
									) : null}
								</div>
								<div className="filter-container column no-padding">
									{showScoreFilter && (
										<>
											<label>Profile Completion</label>
											<RangeInput
												value={this.state.filterScore.split(',')}
												onFinalChange={this.handleSearchByScore}
											/>
										</>
									)}
								</div>
								<div className="filter-container column no-padding">
									{showCreatedOnFilter ? (
										<>
											<label>Created on</label>
											<Dropdown
												value={this.state.filterCreatedOn}
												onValueChange={this.handleSearchByCreatedOn}
												options={CREATED_ON_FILTER_OPTIONS}
												optionValue={'value'}
											/>
										</>
									) : null}
								</div>
								<div className="filter-container column no-padding">
									{showStatusFilter && (
										<>
											<label>Status</label>
											<Dropdown
												value={this.state.filterStatus}
												onValueChange={this.handleSearchByStatus}
												options={candidatesStore.recordStatusOptions}
												optionValue={'value'}
											/>
										</>
									)}
								</div>
								<div className="filter-container column no-padding">
									{this.state.filterTags ? (
										<>
											<label>Tags</label>
											<TagsDropdown
												onValueChange={this.handleSearchByTags}
												value={this.state.filterTags}
												placeholder="Add tag"
											/>
										</>
									) : null}
								</div>
								<div className="filter-container column no-padding">
									{this.state.filterCountries ? (
										<>
											<label>Countries</label>
											<CountryDropdown
												onValueChange={this.handleSearchByCountry}
												value={this.state.filterCountries}
												AsyncProps={{
													isMulti: true
												}}
											/>
										</>
									) : null}
								</div>
								<div className="filter-container column no-padding">
									{showLookingForJobFilter && (
										<>
											<label>Looking For a Job</label>
											<Dropdown
												value={this.state.filterLookingForJob}
												onValueChange={this.handleSearchByLookingForJob}
												options={candidatesStore.lookingForJobOptions}
												optionValue={'value'}
											/>
										</>
									)}
								</div>
							</CandidatesTableFilterList>
						</div>
					) : null}
					{this.state.showColumnsFilter ? (
						<div className="column is-2">
							<CandidatesTableFilterList
								title="View Columns"
								onClose={() => this.handleShowColumnsFilter(true)}
								clearText="Reset Filters"
								onClear={() => {
									this.handleVisibleColumns(
										candidatesStore.tableColumnsOptions
									);
									this.handleScorecardTemplates([]);
								}}>
								<div className="filter-container column no-padding">
									<label>Columns</label>
									<Select
										name="columns"
										value={this.state.visibleColumns}
										valueKey="value"
										className="react-select"
										menuPosition="fixed"
										placeholder="Columns"
										isClearable={true}
										isSearchable={true}
										isMulti
										options={candidatesStore.tableColumnsOptions}
										onChange={value => this.handleVisibleColumns(value)}
									/>
								</div>
								<div className="filter-container column no-padding">
									<label>Scorecard Templates</label>
									<ScorecardTemplateDropdown
										value={this.state.scorecardTemplates}
										onValueChange={value =>
											this.handleScorecardTemplates(value)
										}
									/>
								</div>
							</CandidatesTableFilterList>
						</div>
					) : null}
				</div>
			</div>
		);
	}

	handlePaginationChange = value => {
		this.listStore.handlePaginationChange(value);
		this.updateQueryParams();
	};

	handleSort = column => {
		this.resetPagination();
		this.updateQueryParams();
		this.listStore.handleSort(column);
	};

	handleDelete = item => {
		dialogStore.openDialog(
			{
				title: 'Deactivate Candidate',
				message: 'Are you sure you want to deactivate this candidate?',
				confirmLabel: 'Deactivate',
				confirmButtonClass: 'is-danger'
			},
			() =>
				this.listStore.deleteFromDialog(item, null, item => {
					notificationStore.pushErrorNotification(
						'Deactivation Failed',
						'Failed to delete the candidate.'
					);
				})
		);
	};

	handleActivation = item => {
		dialogStore.openDialog(
			{
				title: 'Activate Candidate',
				message: 'Are you sure you want to activate this candidate?',
				confirmLabel: 'Activate',
				confirmButtonClass: 'is-primary'
			},
			async () => {
				const result = await candidatesStore.activate(item.id);
				if (result.status === 'fail') {
					notificationStore.pushErrorNotification(
						'Activation Failed',
						'Failed to activate the candidate.'
					);
					return;
				}
				notificationStore.pushSuccessNotification(
					'Activation successful',
					'The candidate has been activated.'
				);
				this.listStore.search();
			}
		);
	};

	handleGenerateLinkToken = async candidate => {
		let tokenResponse = await candidatesStore.getLinkToken(candidate.id);

		if (!tokenResponse.data) {
			tokenResponse = await candidatesStore.generateLinkToken(candidate.id);
		}

		modalStore.openModal(
			{ title: 'Private URL', saveButtonLabel: 'Extend' },
			<CandidateProfileLinkToken
				token={tokenResponse.data}
				candidate={candidate}
			/>
		);
	};

	getSkills = async inputValue => {
		inputValue = inputValue.trim();
		const result = await skillStore.getSkills(inputValue);
		return result.data;
	};

	getTags = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		const result = await tagStore.getTags(inputValue);
		return result.data;
	};

	getCountries = async inputValue => {
		inputValue = inputValue.trim();
		const result = await countryStore.getCountries(inputValue);
		return result.data;
	};
}
